// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as moment from "moment";

// ** Axios Imports
import axios from "axios";
import { getUnreviewedPftCount } from "../../pft/store";

export const getData = createAsyncThunk(
  "appPatients/getData",
  async (params) => {
    const response = await axios.get("/v1/Patient/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const getPatientQuestionnaires = createAsyncThunk(
  "appPatients/getPatientQuestionnaires",
  async (params) => {
    const response = await axios.get("/v1/PatientQuestionnaire/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const getPatientToDos = createAsyncThunk(
  "appPatients/getPatientToDos",
  async (params) => {
    const response = await axios.get("/v1/PatientToDo/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const clearSelectedPatient = createAsyncThunk(
  "appPatients/clearSelectedPatient",
  async () => {}
);

export const getPFTsForPatient = createAsyncThunk(
  "appPatients/GetPFTsForPatient",
  async (params) => {
    const response = await axios.get("/v1/PFT/GetAll", {
      params,
    });

    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const getMedicationsDropdown = createAsyncThunk(
  "appPatients/getMedicationsDropdown",
  async (params) => {
    const response = await axios.get(
      "/v1/AstmaActionPlan/medication-dropdown",
      {
        params,
      }
    );
    return {
      data: response.data.data,
    };
  }
);

export const getReactionsDropdown = createAsyncThunk(
  "appPatients/getReactionsDropdown",
  async (params) => {
    const response = await axios.get("/v1/AstmaActionPlan/reactions-dropdown", {
      params,
    });

    return {
      data: response.data.data,
    };
  }
);

export const addPatient = createAsyncThunk(
  "appPatients/addPatient",
  async (patient, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/Patient", patient);
      await dispatch(getData(getState().patients.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const changePatientPassword = createAsyncThunk(
  "appPatients/changePatientPassword",
  async (input, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/Patient/ChangePassword", input);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk(
  "appPatients/sendPasswordResetEmail",
  async (input, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post(
        `/v1/Patient/send-password-reset-email?patientId=${input}`,
        input
      );
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPatientPftGraphData = createAsyncThunk(
  "appPatients/getPatientPftGraphData",
  async (input) => {
    if (!input.dateFrom) {
      input.dateFrom = moment().subtract(25, "days").toISOString();
    }

    if (!input.dateTill) {
      input.dateTill = moment().toISOString();
    }

    // input.type = 0;
    const response = await axios.get("/v1/PatientDashboard/GetPFTData", {
      params: {
        ...input,
      },
    });

    return {
      data: response.data.data,
    };
  }
);

export const getPatientAstmaActionPlan = createAsyncThunk(
  "appPatients/getPatientAstmaActionPlan",
  async (patient, { dispatch }) => {
    if (patient.patientId === 0) return;
    const response = await axios.get(
      `/v1/AstmaActionPlan/${patient.patientId}`
    );
    dispatch(getMedicationsDropdown());
    dispatch(getReactionsDropdown());
    return {
      data: response.data.data,
    };
  }
);

export const getPatient = createAsyncThunk(
  "appPatients/getPatient",
  async (id, { dispatch }) => {
    if (id === 0) return;
    const response = await axios.get(`/v1/Patient/${id}`);

    await dispatch(getPatientPftGraphData({ patientId: id }));
    await dispatch(getPatientAstmaActionPlan({ patientId: id }));

    return {
      data: response.data.data,
    };
  }
);

export const getPatientQuestionnaireDetails = createAsyncThunk(
  "appPatients/getPatientQuestionnaireDetails",
  async (id) => {
    if (id === 0) return;
    const response = await axios.get(`/v1/PatientQuestionnaire/${id}`);

    return {
      data: response.data.data,
    };
  }
);

export const updatePFTStatus = createAsyncThunk(
  "appPatients/updateStatus",
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/PFT/UpdateStatus", data);
      await dispatch(
        getPFTsForPatient(getState().patients.selectedPatientPFTDataParams)
      );
      await dispatch(getUnreviewedPftCount());
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPatientPFTDetails = createAsyncThunk(
  "appPatients/getPatientPFTDetails",
  async (pftId) => {
    if (pftId === 0) return;
    const response = await axios.get(`/v1/PFT/${pftId}`);
    return {
      data: response.data.data,
    };
  }
);

export const getMorePatientActivities = createAsyncThunk(
  "appPatients/GetMorePatientActivities",
  async (params, { getState }) => {
    const patientId = getState().patients.selectedPatient.id;
    params = { ...params, patientId };

    const response = await axios.get("/v1/PatientActivity/GetAllByPatientId", {
      params,
    });

    const activities = getState().patients.patientActivitiesData;

    return {
      data: [...activities, ...response.data.data.items],
      totalCount: response.data.data.totalCount,
    };
  }
);

export const getPatientActivities = createAsyncThunk(
  "appPatients/getPatientActivities",
  async (patientId) => {
    const params = {
      patientId,
      skipCount: 0,
      maxResultCount: 6,
    };

    const response = await axios.get("/v1/PatientActivity/GetAllByPatientId", {
      params,
    });

    return {
      params,
      data: response.data.data.items,
      totalCount: response.data.data.totalCount,
    };
  }
);

export const registerPatientActivity = createAsyncThunk(
  "appPatients/registerPatientActivity",
  async (activity, { dispatch, rejectWithValue }) => {
    try {
      await axios.post("/v1/PatientActivity/Create", activity);
      await dispatch(getPatientActivities(activity.patientId));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePatientActivity = createAsyncThunk(
  "appPatients/updatePatientActivity",
  async (activity, { dispatch, rejectWithValue }) => {
    try {
      await axios.put("/v1/PatientActivity/Update", activity);
      await dispatch(getPatientActivities(activity.patientId));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteActivity = createAsyncThunk(
  "appPatients/deleteActivity",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/PatientActivity/${id}`);
      await dispatch(
        getPatientActivities(getState().patients.selectedPatient.id)
      );
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const updatePatient = createAsyncThunk(
  "appPatients/updatePatient",
  async (patient, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.put("/v1/Patient/Update", patient);

      await dispatch(getPatient(getState().patients.selectedPatient.id));
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePatientAstmaActionPlan = createAsyncThunk(
  "appPatients/updatePatientAstmaActionPlan",
  async (patientAstmaActionPlan, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.put("/v1/AstmaActionPlan", patientAstmaActionPlan);

      await dispatch(
        getPatientAstmaActionPlan({
          patientId: patientAstmaActionPlan.patientId,
        })
      );
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePatientNotes = createAsyncThunk(
  "appPatients/updateNotes",
  async (notes, { rejectWithValue }) => {
    try {
      await axios.put("/v1/Patient/UpdateNotes", notes);
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const insertPatientContact = createAsyncThunk(
  "appPatients/insertContact",
  async (contact, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.post("/v1/PatientContact/Create", contact);
      await dispatch(getPatient(getState().patients.selectedPatient.id));
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updatePatientContact = createAsyncThunk(
  "appPatients/updateContact",
  async (contact, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.put("/v1/PatientContact/Update", contact);
      await dispatch(getPatient(getState().patients.selectedPatient.id));
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getPatientContactDetails = createAsyncThunk(
  "appPatients/getPatientContact",
  async (contact) => {
    return contact;
  }
);

export const getPatientActivityDetails = createAsyncThunk(
  "appPatients/getPatientActivity",
  async (activity) => {
    return activity;
  }
);

export const deletePatient = createAsyncThunk(
  "appPatients/deletePatient",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/Patient/${id}`);
      await dispatch(getData(getState().patients.params));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deletePatientContact = createAsyncThunk(
  "appPatients/deleteContact",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.delete(`/v1/PatientContact/${id}`);
      await dispatch(getPatient(getState().patients.selectedPatient.id));
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getPatientDropdown = createAsyncThunk(
  "appPatients/getPatientDropdown",
  async () => {
    const response = await axios.get("/v1/Patient/GetAllForDropdown");
    return {
      data: response.data.data,
    };
  }
);

export const setSelectedPatientFilter = createAsyncThunk(
  "appPatients/setSelectedPatientId",
  async ({ label, value }) => {
    return {
      label,
      value,
    };
  }
);

export const getFiles = createAsyncThunk(
  "appPatients/getFiles",
  async (params) => {
    const response = await axios.get("/v1/FileUpload/GetAll", {
      params,
    });
    return {
      params,
      data: response.data.data.items,
      total: response.data.data.totalCount,
    };
  }
);

export const uploadFiles = createAsyncThunk(
  "appPatients/fileUpload",
  async (body) => {
    await axios.post("/v1/FileUpload", body, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  }
);

export const getUnseenUploadsCount = createAsyncThunk(
  "appPatients/getUnseenUploadsCount",
  async (patientId) => {
    const response = await axios.get(
      `/v1/FileUpload/GetUnseenUploadsCount?patientId=${patientId}`
    );

    return response.data.data;
  }
);

export const getUnseenUploadsTotalCount = createAsyncThunk(
  "appPatients/getUnseenUploadsTotalCount",
  async () => {
    const response = await axios.get(`/v1/FileUpload/GetUnseenUploadsCount`);

    return response.data.data;
  }
);

export const markUploadAsSeen = createAsyncThunk(
  "appPatients/markUploadAsSeen",
  async (id, { dispatch, getState, rejectWithValue }) => {
    try {
      await axios.put(`/v1/FileUpload/MarkAsSeen?id=${id}`);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadPatientProfileImage = createAsyncThunk(
  "appPatients/downloadPatientProfileImage",
  async (name, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await axios.get(`/v1/Patient/profile-image/${name}`, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 30000,
      });

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const downloadPatientFile = createAsyncThunk(
  "appPatients/downloadPatientFile",
  async (name, { dispatch, getState, rejectWithValue }) => {
    try {
      const result = await axios.get(`/v1/fileupload/file/${name}`, {
        headers: {
          "Content-Type": "application/json",
        },
        responseType: "blob",
        timeout: 30000,
      });

      return result;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const appPatientsSlice = createSlice({
  name: "appPatients",
  initialState: {
    activeTab: "0",
    data: [],
    total: 1,
    params: {},
    loadingPatientData: false,
    selectedPatient: null,
    selectedPatientPftGraphData: null,
    selectedPatientAstmaActionPlan: null,
    selectedPatientFilterId: null,
    selectedPatientFilterLabel: null,
    selectedPatientPFTData: [],
    selectedPatientPFTRecord: {},
    selectedPatientPFTDataParams: {},
    selectedPatientContact: {},
    patientActivitiesData: [],
    patientActivitiesTotalCount: 0,
    isFetchingPFTPatientData: false,
    patientFtpListTotal: 1,
    isSavingPatient: false,
    isUploadingFiles: false,
    selectedPatientActivity: {},
    isSavingPatientNotes: false,
    isSavingPatienAstmaActionPlan: false,
    isSavingPatientPFTRecord: false,
    patientDropdown: [],
    fileData: [],
    totalFiles: 1,
    fileFilterParams: {},
    patientUnseenUploadCount: 0,
    unseenUploadCount: 0,
    patientQuestionnaires: [],
    patientQuestionnairesTotal: 1,
    patientQuestionnaireParams: {},
    patientQuestionnaireDetails: {},
    patientQuestionnaireDetailsLoading: false,
    lastSubmittedQuestionnaire: {},
    patientToDos: [],
    patientToDosParams: {},
    reactionsDropdownOptions: [],
    medicationsDropdownOptions: [],
    patientToDosTotal: 1,
  },
  reducers: {
    handleFileUploadAsSeen: (state, action) => {
      const fileUpload = state.fileData.find(
        (file) => file.id === action.payload
      );

      if (!fileUpload.isSeen) {
        state.patientUnseenUploadCount--;
        state.unseenUploadCount--;
        fileUpload.isSeen = true;
      }
    },
    handleTabToggle: (state, action) => {
      state.activeTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUnseenUploadsCount.fulfilled, (state, action) => {
        state.patientUnseenUploadCount = action.payload;
      })
      .addCase(getUnseenUploadsTotalCount.fulfilled, (state, action) => {
        state.unseenUploadCount = action.payload;
      })
      .addCase(getFiles.fulfilled, (state, action) => {
        state.fileData = action.payload.data;
        state.fileFilterParams = action.payload.params;
        state.totalFiles = action.payload.total;
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.params = action.payload.params;
        state.total = action.payload.total;
      })
      .addCase(getPatientQuestionnaires.fulfilled, (state, action) => {
        state.patientQuestionnaires = action.payload.data;
        state.patientQuestionnaireParams = action.payload.params;
        state.patientQuestionnairesTotal = action.payload.total;
        state.lastSubmittedQuestionnaire = action.payload?.data.sort(
          (objA, objB) =>
            Number(objB.completionTime) - Number(objA.completionTime)
        )[0];
      })
      .addCase(getPatientToDos.fulfilled, (state, action) => {
        state.patientToDos = action.payload.data;
        state.patientToDosParams = action.payload.params;
        state.patientToDosTotal = action.payload.total;
      })
      .addCase(getPatientQuestionnaireDetails.pending, (state, action) => {
        state.patientQuestionnaireDetailsLoading = true;
      })
      .addCase(getPatientQuestionnaireDetails.fulfilled, (state, action) => {
        state.patientQuestionnaireDetails = action.payload?.data;
        state.patientQuestionnaireDetailsLoading = false;
      })
      .addCase(getPatient.pending, (state) => {
        state.loadingPatientData = true;
      })
      .addCase(getPatient.rejected, (state) => {
        state.loadingPatientData = false;
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        state.selectedPatient = action.payload?.data;
        state.loadingPatientData = false;
      })
      .addCase(addPatient.pending, (state) => {
        state.isSavingPatient = true;
      })
      .addCase(addPatient.fulfilled, (state) => {
        state.isSavingPatient = false;
      })
      .addCase(addPatient.rejected, (state) => {
        state.isSavingPatient = false;
      })
      .addCase(changePatientPassword.pending, (state) => {
        state.isSavingPatient = true;
      })
      .addCase(changePatientPassword.fulfilled, (state) => {
        state.isSavingPatient = false;
      })
      .addCase(changePatientPassword.rejected, (state) => {
        state.isSavingPatient = false;
      })
      .addCase(getPFTsForPatient.pending, (state) => {
        state.isFetchingPFTPatientData = true;
      })
      .addCase(getPFTsForPatient.fulfilled, (state, action) => {
        state.isFetchingPFTPatientData = false;
        state.selectedPatientPFTData = action.payload.data;
        state.selectedPatientPFTDataParams = action.payload.params;
        state.patientFtpListTotal = action.payload.total;
      })
      .addCase(updatePFTStatus.pending, (state) => {
        state.isSavingPatientPFTRecord = true;
      })
      .addCase(updatePFTStatus.fulfilled, (state) => {
        state.isSavingPatientPFTRecord = false;
      })
      .addCase(updatePFTStatus.rejected, (state) => {
        state.isSavingPatientPFTRecord = false;
      })
      .addCase(getPatientPFTDetails.fulfilled, (state, action) => {
        if (!action.payload) {
          state.selectedPatientPFTRecord = {};
        }

        state.selectedPatientPFTRecord = action.payload?.data;
      })
      .addCase(getPatientActivities.fulfilled, (state, action) => {
        state.patientActivitiesData = action.payload.data;
        state.patientActivitiesTotalCount = action.payload.totalCount;
      })
      .addCase(clearSelectedPatient.fulfilled, (state) => {
        state.selectedPatientPFTData = [];
        state.selectedPatientPFTRecord = {};
        state.selectedPatientPFTDataParams = {};
        state.isSavingPatientPFTRecord = false;
        state.patientActivitiesData = [];
      })
      .addCase(getMorePatientActivities.fulfilled, (state, action) => {
        state.patientActivitiesData = action.payload.data;
        state.patientActivitiesTotalCount = action.payload.totalCount;
      })
      .addCase(updatePatientNotes.pending, (state) => {
        state.isSavingPatientNotes = true;
      })
      .addCase(updatePatientNotes.fulfilled, (state) => {
        state.isSavingPatientNotes = false;
      })
      .addCase(updatePatientNotes.rejected, (state) => {
        state.isSavingPatientNotes = false;
      })
      .addCase(updatePatient.pending, (state) => {
        state.isSavingPatient = true;
      })
      .addCase(updatePatient.fulfilled, (state) => {
        state.isSavingPatient = false;
      })
      .addCase(updatePatient.rejected, (state) => {
        state.isSavingPatient = false;
      })
      .addCase(uploadFiles.pending, (state) => {
        state.isUploadingFiles = true;
      })
      .addCase(uploadFiles.fulfilled, (state) => {
        state.isUploadingFiles = false;
      })
      .addCase(uploadFiles.rejected, (state) => {
        state.isUploadingFiles = false;
      })
      .addCase(getPatientDropdown.fulfilled, (state, action) => {
        const preppedPatientDropdown = [];

        action.payload.data.forEach((element) => {
          preppedPatientDropdown.push({
            value: element.id,
            label: element.name,
          });
        });

        state.patientDropdown = preppedPatientDropdown;
      })
      .addCase(setSelectedPatientFilter.fulfilled, (state, action) => {
        state.selectedPatientFilterId = action.payload.value;
        state.selectedPatientFilterLabel = action.payload.label;
      })
      .addCase(getPatientContactDetails.fulfilled, (state, action) => {
        state.selectedPatientContact = action.payload;
      })
      .addCase(updatePatientContact.fulfilled, (state) => {
        state.selectedPatientContact = {};
      })
      .addCase(getPatientActivityDetails.fulfilled, (state, action) => {
        state.selectedPatientActivity = action.payload;
      })
      .addCase(updatePatientActivity.fulfilled, (state) => {
        state.selectedPatientActivity = {};
      })
      .addCase(getPatientPftGraphData.fulfilled, (state, action) => {
        action.payload.data.data.map((item) => {
          if (item.value === 0) item.value = null;
        });
        state.selectedPatientPftGraphData = action.payload.data;
      })
      .addCase(getPatientAstmaActionPlan.fulfilled, (state, action) => {
        const reactionsDropdown = [];
        action.payload.data?.reactions?.forEach((element) => {
          if (element.value !== null) {
            reactionsDropdown.push({
              value: element.value,
              label: element.label,
            });
          } else {
            reactionsDropdown.push({
              value: element.label,
              label: element.label,
            });
          }
        });

        action.payload.data.reactions = reactionsDropdown;
        state.selectedPatientAstmaActionPlan = action.payload.data;
      })
      .addCase(updatePatientAstmaActionPlan.pending, (state) => {
        state.isSavingPatienAstmaActionPlan = true;
      })
      .addCase(updatePatientAstmaActionPlan.fulfilled, (state) => {
        state.isSavingPatienAstmaActionPlan = false;
      })
      .addCase(updatePatientAstmaActionPlan.rejected, (state) => {
        state.isSavingPatienAstmaActionPlan = false;
      })
      .addCase(getReactionsDropdown.fulfilled, (state, action) => {
        state.reactionsDropdownOptions = action.payload.data;
      })
      .addCase(getMedicationsDropdown.fulfilled, (state, action) => {
        state.medicationsDropdownOptions = action.payload.data;
      });
  },
});

export const { handleFileUploadAsSeen, handleTabToggle } =
  appPatientsSlice.actions;

export default appPatientsSlice.reducer;
